<template>
  <div class="dataCenter">
    <NavigationArrow link="/clearing" :position="[4, 2]" :rotation="-70" color="#682A39"/>
    <header>
      <h3 v-if="!tagsVisibility">{{ filteredQuestion.question }}</h3>
      <h3 v-if="tagsVisibility">Most popular tags</h3>
    </header>
    <content>
      <span class="button left" @click="moveWheel('left')">&lt;</span>
      <div v-if="!tagsVisibility" class="chartBackground">
        <img v-show="index == questionId" v-for="item, index in questions" :key="index"
          :src="require(`@/assets/chart-flowers-${index + 1}-1x.png`)"
          :srcset="require(`@/assets/chart-flowers-${index + 1}-1x.png`) + ` 2000w, ` + require(`@/assets/chart-flowers-${index + 1}-2x.png`) + ` 3840w`" alt="" />
      </div>
      <svg v-if="!tagsVisibility" class="pieChart" viewBox="0 0 20 20">
        <circle r="10" cx="10" cy="10" fill="black"
          stroke="#682A39"
          stroke-width="0.2" />
        <circle r="4.95" cx="10" cy="10" fill="transparent"
          stroke="#682A39"
          stroke-width="10"
          :stroke-dasharray="`calc(${ yes(questionId + 1) } * 31.415 / 100) 31.415`"
          transform="rotate(-90) translate(-20)" />
      </svg>
      <div v-if="!tagsVisibility" class="legend">
        <p>{{ yes(questionId + 1) }}% Yes</p>
        <p>{{ no(questionId + 1) }}% No</p>
      </div>
      <div v-if="tagsVisibility" class="tags">
        <p :class="[{selected: selectedTags.includes(item.tag)}, item.tag]" v-for="(item, index) in tags" :key="index">{{ Math.round(item.count / tags.length * 100) }}% {{ item.tag }}</p>
      </div>
      <span class="button right" @click="moveWheel('right')">&gt;</span>
    </content>
    <footer>
      <ArrivalBoard :msg="visitorCount" />
    </footer>
  </div>
</template>

<script>
import ArrivalBoard from '@/components/ArrivalBoard.vue'
import NavigationArrow from '@/components/NavigationArrow.vue'

export default {
  name: 'DataCenter',
  components: {
    ArrivalBoard,
    NavigationArrow
  },
  data () {
    return {
      questions: [],
      questionId: 0,
      answers: [],
      tags: [],
      tagsVisibility: false,
      selectedTags: [],
      visitorCount: ''
    }
  },
  methods: {
    async getTags () {
      const response = await fetch('https://ayla.raphaeldas.ch/query.php?item=tags')
      this.tags = await response.json()
    },
    async getAnswers () {
      const response = await fetch('https://ayla.raphaeldas.ch/query.php?item=answers')
      const data = await response.json()
      this.answers = data[0]
    },
    async getVisitorCount () {
      const response = await fetch('https://ayla.raphaeldas.ch/query.php?item=visitorCount')
      const data = await response.json()
      this.visitorCount = `${data[0].count} people visited the jungle`
    },
    moveWheel (direction) {
      if (direction === 'left') {
        if (this.questionId > 0) {
          this.questionId--
        } else if (this.tagsVisibility) {
          this.questionId = Object.keys(this.questions).length - 1
          this.tagsVisibility = false
        } else {
          this.showTags()
        }
      } else if (direction === 'right') {
        if (this.questionId < Object.keys(this.questions).length - 1) {
          this.questionId++
        } else if (this.tagsVisibility) {
          this.questionId = 0
          this.tagsVisibility = false
        } else {
          this.showTags()
        }
      }
    },
    showTags () {
      this.tagsVisibility = true
    },
    yes (question) {
      return Math.round(this.answers[`q${question}`] / this.answers.total * 100) || 0
    },
    no (question) {
      return (100 - Math.round(this.answers[`q${question}`] / this.answers.total * 100)) || 0
    }
  },
  computed: {
    filteredQuestion: {
      get () {
        return this.questions[this.questionId]
      }
    }
  },
  created () {
    this.questions = this.$store.getters.getQuestions
    this.selectedTags = this.$store.getters.getSelectedTags
  },
  async mounted () {
    this.getAnswers()
    this.getVisitorCount()
    this.getTags()
  }
}
</script>

<style scoped>
  .dataCenter {
    width: 100vw;
    height: 100vh;
    background-color: #000;
    background-image: -webkit-image-set(url('~@/assets/data-center-bg-1x.png') 1.25x, url('~@/assets/data-center-bg-2x.png') 2x);
    background-size: cover;
    display: grid;
    grid-template-rows: auto 2fr auto;
  }

  .navigationArrow {
    stroke: #fff;
  }

  header > * {
    color: #fff;
    margin: 7vh 0vw 1vh;
    font-size: 2.5vh;
    font-weight: 500;
  }

  content span {
    font-size: 20vh;
    color: #824859;
    font-weight: 700;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  content span.left {
    left: 2vw;
  }

  content span.right {
    right: 2vw;
  }

  content {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: center;
    justify-items: center;
  }

  .chartBackground {
    position: absolute;
    top: 50%;
    left: 37.5%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }

  .pieChart {
    grid-column-start: 2;
    overflow: visible;
    z-index: 1;
  }

  .legend {
    color: #fff;
  }

  .legend > p {
    border: 0.4vh solid #824859;
    padding: 1vh 1vw;
    margin: 3vh;
    background-color: #000;
    border-radius: 2vh;
    font-family: 'Courier Prime';
    font-size: 4vh;
    text-align: left;
  }

  .legend > p:first-child {
    background-color: #682A39;
  }

  .tags {
    color: #fff;
    font-size: 2vh;
    font-family: 'Courier Prime';
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    grid-column-start: 1;
    grid-column-end: 4;
    width: 70vw;
  }

  .tags p {
    margin: 0.6vh 0.4vw;
    background-color: #682A39;
    border: 0.4vh solid #682A39;
    border-radius: 4vh;
    padding: 0.3vh 0.7vw;
    height: min-content;
  }

  .tags p.selected {
    border-color: #AFFF85;
  }

  .arrivalBoard {
    border-top: 0.4vh solid #FFB19C;
    border-bottom: 0.4vh solid #FFB19C;
    color: #C1272D;
    margin-bottom: 2vh;
    font-weight: 900;
    font-size: 9vh;
    padding: 1vh 0vw;
  }

</style>
