<template>
  <div class="chatBot">
    <ElizaBot />
    <NavigationArrow link="/clearing" :position="[90, 80]" :rotation="115" color="#fff"/>
  </div>
</template>

<script>
import ElizaBot from '@/components/ElizaBot.vue'
import NavigationArrow from '@/components/NavigationArrow.vue'
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
    ElizaBot,
    NavigationArrow
  }
}
</script>

<style scoped>
  .chatBot {
    width: 100vw;
    height: 100vh;
    background-color: #006B84;
    background-image: -webkit-image-set(url('~@/assets/chatbot-bg-1x.png') 1.25x, url('~@/assets/chatbot-bg-2x.png') 2x);
    background-size: cover;
    background-position: center;
    overflow-y: auto;
  }
</style>
