<template>
  <div class="reference-overlay" :class="{active: referenceVisibility}">
    <object :data="require(`@/assets/clearing/references.svg`)" type="image/svg+xml">
      <img src="@/assets/clearing/references.svg" alt="References" />
    </object>
    <img @click="close" class="button" src="@/assets/close-icon.svg" alt="Close Info Overlay" />
  </div>
</template>

<script>
export default {
  name: 'ReferenceOverlay',
  methods: {
    close () {
      this.$store.dispatch('hideReference')
    }
  },
  computed: {
    referenceVisibility: {
      get () {
        return this.$store.getters.referenceVisibility
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

* {
  --blueRGB: 100 144 221;
}

.reference-overlay {
  position: absolute;
  width: 90vw;
  height: 95vh;
  margin: 2vh 5vw;
  background-color: rgb(255 245 243 / 95%);
  border: 0.2vw solid #000;
  top: 100vh;
  display: flex;
  transition: top 1s ease-in 0s;
  z-index: 100;
}

.reference-overlay.active {
  top: 0vh;
  transition: top 1s ease-out 0s;
}

object {
  flex-grow: 1;
  margin: 3vh 3vw;
}

img {
  position: absolute;
  height: 5vh;
  top: 1vh;
  right: 1vh;
}

</style>
