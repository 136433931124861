<template>
  <div class="elizaBot">
    <div id="chatContainer">
      <div class="chatBox" ref="chatBox">
        <span v-html="chat"></span>
      </div>
      <div class="input">
      <form @submit.prevent="">
        <input @keyup.enter="speak" v-model="msg" autofocus class="msg">
      </form>
      </div>
    </div>
  </div>
</template>

<script>
import ElizaBot from '@itsbth/eliza-as-promised'
export default {
  name: 'ElizaBot',
  data () {
    return {
      eliza: null,
      chat: '',
      msg: ''
    }
  },
  mounted () {
    this.eliza = new ElizaBot()
    this.chat = '&gt; ' + this.eliza.getInitial()
  },
  methods: {
    async speak () {
      const response = await this.eliza.getResponse(this.msg)
      if (response.reply) {
        const delay = response.reply.length * 100 + Math.random()
        this.chat += `<br/>* ${this.msg}<br/>&gt; `
        setTimeout(() => {
          this.chat += response.reply
        }, delay)
      }
      if (response.final) {
        const delay = response.reply.length * 100 + Math.random()
        this.chat += `<br/>* ${this.msg}<br/>&gt; `
        setTimeout(() => {
          this.chat += response.final
          console.log('discussion discussed')
        }, delay)
      }
      this.msg = ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  ::-webkit-scrollbar {
      width: 0;
      background: transparent;
  }

  .elizaBot {
    font-family: 'Courier Prime';
    width: 100vw;
    height: 100vh;
    background-image: -webkit-image-set(url('~@/assets/vines-front-1x.png') 1.25x, url('~@/assets/vines-front-2x.png') 2x);
    background-position: center;
    background-size: cover;
  }

  #chatContainer {
    display: flex;
    flex-direction: column;
    width: 50vw;
    max-width: 800px;
    height: min-content;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .chatBox {
    background-color: rgb(0 0 0 / 95%);
    padding: 4vh 3vw;
    border-radius: 4vh;
    border: 0.5vh solid #fff;
    color: #00FF00;
    height: 45vh;
    text-align: left;
    overflow-y: auto;
    display: flex;
    margin-bottom: 4vh;
    font-size: 20px;
  }

  .chatBox > span {
    align-self: flex-end;
    min-height: 100%;
    line-height: 1.35;
  }

  .input {
    height: 7vh;
    background-color: #fff;
    border: 0.5vh solid #000;
    border-radius: 3vh;
    padding: 2vh 0.5vw;
  }

  input.msg {
    all: unset;
    border: 0.5vh solid #000;
    width: calc(100% - 4vw);
    height: 100%;
    padding: 0.5vh 1.5vw 2vh;
    text-align: left;
    font-size: 2.3vh;
  }
</style>
