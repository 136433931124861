<template>
  <div class="clearing">
    <img class="button link" @click="showReference" src="@/assets/clearing/button_text.svg" alt="Link references"/>
    <div class="leafs">
      <router-link class="router button" to="/furniture-game">
        <img src="@/assets/clearing/lamp-1x.png"
        srcset="@/assets/clearing/lamp-1x.png 2000w, @/assets/clearing/lamp-2x.png 3840w" />
      </router-link>
    </div>
    <NavigationArrow link="/chatBot" :position="[8, 10]" :rotation="-50" color="#BBFFD6"/>
    <NavigationArrow link="/stories" :position="[17, 75]" :rotation="-130" color="#BBFFD6"/>
    <NavigationArrow link="/dataCenter" :position="[85, 72]" :rotation="125" color="#BBFFD6"/>
    <ReferenceOverlay />
  </div>
</template>

<script>
import NavigationArrow from '@/components/NavigationArrow.vue'
import ReferenceOverlay from '@/components/ReferenceOverlay.vue'
// @ is an alias to /src

export default {
  name: 'Clearing',
  components: {
    NavigationArrow,
    ReferenceOverlay
  },
  methods: {
    showReference () {
      this.$store.dispatch('showReference', true)
    }
  }
}
</script>

<style scoped>
  .clearing {
    width: 100vw;
    height: 100vh;
    background-color: #006B84;
    background-image: -webkit-image-set(url('~@/assets/clearing-bg-1x.png') 1.25x, url('~@/assets/clearing-bg-2x.png') 2x);
    background-size: cover;
  }

  .link {
    position: absolute;
    z-index: 0;
    bottom: 2vh;
    left: 50%;
    height: 40vh;
    transform: translateX(-50%);
  }

  .leafs {
    width: 100vw;
    height: 100vh;
    background-image: -webkit-image-set(url('~@/assets/clearing-leafs-1x.png') 1.25x, url('~@/assets/clearing-leafs-2x.png') 2x);
    background-size: cover;
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }

  .router {
    position: absolute;
    display: inline-block;
    left: 50%;
    top: 0;
    height: 10vh;
    transform: translateX(-50%);
    pointer-events: all;
  }

</style>
